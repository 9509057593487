import { Modal } from 'antd'
import React from 'react'
import CopyBox from '../../../components/copyBoard'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './index.less'
interface EmailModalProps {
    isModalOpen:boolean,
    handleCancel:()=>void

}
const EmailModal:React.FC<EmailModalProps> = ({isModalOpen,handleCancel}) => {
    const [copyStatus,setCopyStatus] = React.useState(0)
    const copyFunc = ()=>{
        if(copyStatus) return 
        setCopyStatus(1)
        
        setTimeout(()=>{
            setCopyStatus(0)
        },2000)
    }
    return (
        <Modal 
        title={<div className='w100 flex justify_center fs_4 fw_b text-white mb_4'>Contact Us</div>}
        open={isModalOpen}
        onCancel={handleCancel}
        width={437}
        centered
        footer={null}
        className='email-modal'
        closeIcon={null}
        >
            <div className='flex column align_center'>
                    <div className='text-1 fs_2 contact-text flex align_center'>
                            <p>Questions? Suggestions?</p>
                            <p>Contact us via email at</p>
                         
                    </div>
                            <CopyToClipboard text='info@polarise.org'>
                                    <div className='copy-email-btn text-white fw_b cursor flex align_center' onClick={copyFunc}>
                                        <div>info@polarise.org</div>
                                        <div className={`ml_3 copy-btn-status ${copyStatus&&'suc'}`}>
                                                <i className='iconfont icon-icon-copy fw_m'></i>
                                                <div className='suc-bg flex align_center justify_center'>
                                                    <i className='iconfont icon-icon-yep fs_1 fw_m'></i>
                                                </div>
                                        </div>
                                    </div>

                            </CopyToClipboard>
            </div>
        </Modal>    )
}

export default EmailModal