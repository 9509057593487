import { notification } from "antd"
import React from "react"
import './index.less'
const Notification = (message:String,type:'warning' | 'success' = 'warning') => {
    notification.open({
        message: <div className='flex align_center'>
                    <i className={`iconfont ${type==='warning'?'icon-icon-note':'icon-icon-Finish'} mr_4 is_5`} />
                    <span className="fw_m fs_2 text_1">{message}</span>
                </div>,
        closeIcon: <div></div>,
        duration: 2,
    })
}
export default Notification




// import { notification } from 'antd';
// import './index.scss'
// function NoticeCallbackFun (){
//     this.obj = {
//         closeIcon: <div></div>,
//         style:{
//             maxWidth:'330px',
//             borderRadius:'12px',
//             padding:'24px',
//             boxShadow: '0px 4px 48px rgba(0, 0, 0, 0.06)'
//         },
//         duration:2
//     }
//     // message ==> title，
//     // description <Node|String> info
//     this.success = (message,description)=>{notification.open(
//         typeof(message)==='string'?
//         {
//         ...this.obj,
//         message:<div className='fw_m fs_2 text_2'>{message}</div> ,
//         description: <div className='fs_2 fw_m text_3'>{description}</div> ,
//         icon: <img className='block' style={{ width: '24px' }} src='/images/create/success.png' alt="" />,
//         }
//         :
//         message
//       );}
//     this.warning = (message,description)=>{notification.open(
//         typeof(message)==='string'?
//         {
//         ...this.obj,
//         message:<div className='fw_m fs_2 text_2'>{message}</div> ,
//         description: <div className='fs_2 fw_m text_3'>{description}</div> ,
//         icon: <img className='block' style={{width:'24px'}} src='/images/create/warn.png' alt="" />,
//         }
//         :
//         message
//       );}
//     this.error = (message,description)=>{notification.open(
//         typeof(message)==='string'?
//         {
//         ...this.obj,
//         message:<div className='fw_m fs_2 text_2'>{message}</div> ,
//         description: <div className='fs_2 fw_m text_3'>{description}</div> ,
//         icon: <img className='block' style={{ width: '24px' }} src='/images/create/error.png' alt="" />,
//         }
//         :
//         message
//         );}
//     this.failed = (message,description)=>{notification.open(
//         typeof(message)==='string'?
//         {
//         ...this.obj,
//         message:<div className='fw_m fs_2 text_2'>{message}</div> ,
//         description: <div className='fs_2 fw_m text_3'>{description}</div> ,
//         icon: <img className='block' style={{ width: '24px' }} src='/images/create/error.png' alt="" />,
//         }
//         :
//         message
//         );}
// }
// const NoticeCallback = new NoticeCallbackFun()
// export default NoticeCallback
