import React,{ useRef } from 'react';
import './index.less'

interface InputBoxProps {
    type?: string;
    errTip?: string;
    width?: string;
    noborder?: boolean;
    size?: string;
    clear?: boolean;
    onlyShow?: boolean;
    round?: boolean;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    style?: Record<string, string>;
    maxLength?: number;
    title?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    addonBefore?: React.ReactNode;
    addonAfter?: React.ReactNode;
}

const InputBox:React.FC<InputBoxProps> = (props)=>{


    const inputElement = useRef(null);

    const mergecss = ()=>{
        const style = ['inputType'];
        if(props.errTip){
            style.push("err")
        }
        if(props.width==='smallWidth'){
            style.push('smallWidth')
        }
        if(props.noborder){
            style.push('noborder')
        }
        if(props.size){
            props.size==='small' && style.push('small')
            props.size==='middle' && style.push('middle')
            props.size==='large' && style.push('large')
            props.size==='moreLarge' && style.push('moreLarge')
        }
        if(props.clear){
            style.push('clearInputValue b_g_4')
        }
        if(props.onlyShow){
            style.push('onlyShow')
        }
        if(props.round){
            style.push('round')
        }
        if(props.className){
            style.push(props.className)
        }
        return style.join(" ");
    }




    return (
        <div className={`inputChange ${props.type || ''}`}>
            <div className={mergecss()}>
                {
                    props.addonBefore && <div className='addonBefore'>{props.addonBefore}</div>
                }
                <input
                    disabled={props.disabled ? true : false}
                    ref={inputElement}
                    type={ props.type ? props.type : "text" }
                    readOnly={props.disabled}
                    placeholder={props.placeholder}
                    value={props.value}
                    style={props.style}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        props.onChange && props.onChange(e);
                      }}                    maxLength={props.maxLength}
                    title={props.title || ''}
                    />
                {
                    props.addonAfter
                    ?   <div className={`rightName white_space_nowrap pointer`}>{props.addonAfter}</div>
                    :   null
                }
            </div>
        </div>
    )

}



export default InputBox;

