import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './routers/index';
import reportWebVitals from './reportWebVitals';
import '@/styles/index.less';
import '@/styles/public.less'
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// Clickjacking protection
if (window.top !== window.self) {
  (window.top as Window).location = window.self.location;
}
// test iframe Clickjacking
//  if (window.top !== window.self) {
//   document.body.innerHTML = '<h1>Clickjacking attempt detected!</h1>';
//   console.log('Clickjacking attempt detected.');
// } else {
//   console.log('Page is not embedded in an iframe.');
// }
root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
