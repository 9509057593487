export const langList = [
    { title: "English", info: 'en' },
    { title: "中文简体", info: 'zh-CN'},
    { title: "中文繁体",  info: 'zh-TW'},
]

export const defaultLang = langList[0];

export const getLangObject = ()=>{

    const local = window.localStorage.getItem('LANGUAGE');
    let lang = defaultLang;

    if(!local || typeof local !== 'string' || local.length < 10){
        return lang;
    }

    try {
        lang = JSON.parse(local);
    } catch(e){
        lang = langList[0];
    }
    return lang;
}

const langMapping:{
    [key:string]:string
} = {
    'en': 'en',
    'zh-CN': 'zh-cn',
    'zh-TW': 'zh-hant',
}

export function queryLang() {
    let langObj = getLangObject()
    let lang = langObj.info
    return langMapping[lang]
}

export const setLangObject = (lan:string) => {
    window.localStorage.setItem('LANGUAGE', JSON.stringify(lan));
    window.location.reload();
};
