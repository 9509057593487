import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locales/en.json'
import zhCN from './locales/zh.json'
import zhTW from './locales/zh-tw.json'

import { getLangObject } from './langUtil';

i18next
.use(initReactI18next) // passes i18n down to react-i18next
.init({
lng: getLangObject().info,
interpolation: {
escapeValue: false // react already safes from xss
},
resources: {
//语言包资源
'en': {
translation: {
...en
}
},
'zh-CN': {
translation: {
...zhCN
},
},
'zh-TW': {
translation: {
...zhTW
}
}
}
});

export default i18next;