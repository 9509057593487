import React, { LazyExoticComponent } from "react";

interface RouteType {
    pathname: string;
    component: LazyExoticComponent<any>;
    exact: boolean;
    title?:string;
    icon?:string;
    children?:RouteType[]
}
const routesArr: RouteType[] = [
    {
        pathname: "/",
        component: React.lazy(() => import("../views/HomePage")),
        exact: true,
    },
    {
        pathname: "/polarfighters",
        component: React.lazy(() => import("../views/polarFighters")),
        exact: true,
    },
    // {
    //     pathname: "/newscenter",
    //     component: React.lazy(() => import("../views/newsCenter")),
    //     exact: true,
    // },
    // {
    //     pathname: "/collections",
    //     component: React.lazy(() => import("../views/collections")),
    //     exact: true,
    // },
    {
        pathname: "/introduce",
        component: React.lazy(() => import("../views/introduce")),
        exact: true,
    },
    {
        pathname: "/policy",
        component: React.lazy(() => import("../views/introduce")),
        exact: true,
    },
]
export default routesArr;