import axios, { AxiosRequestConfig } from "axios";

const url = process.env.NODE_ENV === 'development' ? 'https://api-app.pawnfi.com' : `https://api-app.pawnfi.com`

const API = {
  getNewsList: url + '/api/stat/querynewsinfo.do',
  getNewsDetail: url + '/api/stat/querynewsinfobyid.do',
  adduseremail: url + '/api/pawnfi/adduseremail.do',
  getCollections: url + "/api/stat/queryallcollateraltoken.do",
  submitCollection: url + '/api/pawnfi/tokenwhitelistapply.do'
};

interface QueryParams {
  name: keyof typeof API;
  data?: Record<string, any>;
}

export default function query(params: QueryParams): Promise<any> {
  const requestConfig: AxiosRequestConfig = {
    method: 'GET',
    url: API[params.name],
    params: {
      p: JSON.stringify(params.data || {}),
    },
  };

  return axios(requestConfig);
}
